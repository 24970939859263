//

import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';
import {
  clearIndexDBStore,
  updateIndexDBRecord,
} from '../../indexDB/indexdbUtilsFunctions';
import { INDEX_DB_CONFIG } from '../../indexDB/configDB';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  existingReports: [],
  selectedExistingReport: {},
  error: null,
  successMessage: null,

  // duplicate report
  isLoadingDupReport: false,
  isDupReportSuccess: null,
  isDupReportFail: null,

  // updating report
  isUpdatingReport: false,
  updateSuccessMessage: null,
  updateErrorMessage: null,
};

const slice = createSlice({
  name: 'existing-reports',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
      state.existingReports = [];
      state.selectedExistingReport = {};
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET
    getAllReportsSuccess(state, action) {
      state.isLoading = false;
      state.existingReports = action.payload;
    },

    getSingleExistingReportsSuccess(state, action) {
      state.isLoading = false;
      state.selectedExistingReport = action.payload;
    },

    // Duplicate report ...
    postDuplicateReportLoading(state) {
      state.isLoadingDupReport = true;
      state.isDupReportSuccess = null;
      state.isDupReportFail = null;
    },

    postDuplicateReportSuccess(state, action) {
      state.isLoadingDupReport = false;
      state.isDupReportSuccess = action.payload?.message;
      state.isDupReportFail = null;
    },

    postDuplicateReportError(state, action) {
      state.isLoadingDupReport = false;
      state.isDupReportSuccess = action.payload?.message;
      state.isDupReportFail = null;
    },

    // Updating report ...
    startUpdatingReport(state) {
      state.isUpdatingReport = true;
      state.updateSuccessMessage = null;
      state.updateErrorMessage = null;
    },

    updateExistingReportSuccess(state, action) {
      state.isUpdatingReport = false;
      state.updateSuccessMessage = action.payload;
      state.updateErrorMessage = null;
    },

    updateExistingReportError(state, action) {
      state.isUpdatingReport = false;
      state.updateSuccessMessage = null;
      state.updateErrorMessage =
        action.payload?.message ?? 'Something went wrong!';
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getAllExistingReports() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/v1/scout-reports');
      if (response?.data?.status === 1) {
        if (Array.isArray(response.data?.data)) {
          await clearIndexDBStore(INDEX_DB_CONFIG.existingReports.storeObject);
          await updateIndexDBRecord(
            INDEX_DB_CONFIG.existingReports.storeObject,
            response.data.data
          );
          dispatch(slice.actions.getAllReportsSuccess(response.data.data));
        } else {
          console.log('Invalid API response of v1/scout-reports');
        }
      } else {
        dispatch(slice.actions.hasError(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// NOTE: this API is not in use after implementation of indexDB ...
export function getSingleExistingReports(reportId) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/v1/scout-reports/${reportId}`);
      if (response?.data?.status === 1) {
        dispatch(
          slice.actions.getSingleExistingReportsSuccess(
            response.data?.data[0] ?? []
          )
        );
      } else {
        dispatch(slice.actions.hasError(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// this function will run during online mode only ...
export function updateExistingReport(
  putObj,
  data,
  shouldFireUploadAPI,
  deleteImgIds
) {
  return async () => {
    dispatch(slice.actions.startUpdatingReport());
    try {
      // step 1: Update the report content
      const response = await axios.put('/api/v1/scout-report-update', {
        data: putObj,
      });

      // step 2: After successful submission, we will upload the attachments ...
      if (response?.data?.status === 1) {
        if (shouldFireUploadAPI) {
          await axios.post('/api/v1/upload-report-files', data, {
            headers: {
              ContentType: 'multipart/form-data',
            },
          });
        }

        // step 3: After successful submission, fire delete API if there is any attachment user deleted ...
        if (deleteImgIds?.length) {
          const deleteObj = {
            attachment_ids: JSON.stringify({ attachment_ids: deleteImgIds }),
          };

          await axios.delete('/api/v1/remove-report-files', {
            data: new URLSearchParams(deleteObj),
          });
        }

        dispatch(
          slice.actions.updateExistingReportSuccess(response.data?.message)
        );
      } else {
        dispatch(slice.actions.updateExistingReportError(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.updateExistingReportError(error));
    }
  };
}

// generate duplicate report
export function postDuplicateReport(reportId, data) {
  return async () => {
    dispatch(slice.actions.postDuplicateReportLoading());
    try {
      const response = await axios.post(
        `/api/v1/scout-reports/${reportId}/duplicate`,
        data
      );
      if (response?.data?.status === 1) {
        dispatch(slice.actions.postDuplicateReportSuccess(response.data));
      } else {
        dispatch(slice.actions.postDuplicateReportError(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.postDuplicateReportError(error));
    }
  };
}
