//

import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';
// pwa report reducers ...
import PWACustomers from './pwa-report/customers';
import PWAPeoples from './pwa-report/peoplesList';
import PWACustomerLocation from './pwa-report/customerLocation';
import PWACustomerLocationBlock from './pwa-report/customerLocationBlock';
import PWALocationCommodities from './pwa-report/locationCommodity';
import PWASubmitReport from './pwa-report/submitReport';
import PWAAllReports from './pwa-report/existingReports';
import PWAScoutInventoryReducer from './scoutInventory/scoutInventory';
import PWASendMailToPeoples from './pwa-report/sendMailToPeoples';

// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

const rootReducer = combineReducers({
  // pwa reducers
  PWACustomers,
  PWAPeoples,
  PWASendMailToPeoples,
  PWACustomerLocation,
  PWACustomerLocationBlock,
  PWALocationCommodities,
  PWASubmitReport,
  PWAAllReports,
  PWAScoutInventoryReducer,
});

export { rootPersistConfig, rootReducer };
