//

import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
  isSendMailLoading: false,
  error: null,
  data: [],
  sendMailSuccMsg: null,
  sendMailErrMsg: null,
};

const slice = createSlice({
  name: 'sendMailToPeoples',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isSendMailLoading = true;
      state.data = [];
      state.sendMailSuccMsg = null;
      state.sendMailErrMsg = null;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isSendMailLoading = false;
      state.sendMailErrMsg = action.payload.message;
      state.data = [];
    },

    // CREATE EVENT
    createEventSuccess(state, action) {
      state.isSendMailLoading = false;
      state.data = action.payload;
      state.sendMailSuccMsg = action.payload.message;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function sendMailToPeoples(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/api/v1/mail-scout-report', data, {
        headers: {
          ContentType: 'multipart/form-data',
        },
      });
      if (response?.data?.status === 1) {
        dispatch(slice.actions.createEventSuccess(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
