import { Box } from '@mui/material';

// ----------------------------------------------------------------------

//new Existing Reports page -> back arrow icon
export default function ArrowBackIcon({ ...other }) {
  return (
    <Box {...other}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={28}
        height={28}
        viewBox="0 0 24 24"
      >
        <path
          fill="currentColor"
          d="m7.825 13l5.6 5.6L12 20l-8-8l8-8l1.425 1.4l-5.6 5.6H20v2H7.825Z"
        />
      </svg>
    </Box>
  );
}
