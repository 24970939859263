import React, { createContext, useState, useEffect } from "react";
import PropTypes from "prop-types";

const InternetConnectionContext = createContext({ isConnected: true });

InternetConnectionProvider.propTypes = {
  children: PropTypes.node,
};

function InternetConnectionProvider({ children }) {
  const [internetStatus, setInternetStatus] = useState(false);

  useEffect(() => {
    const checkInternetConnectivity = async () => {

      const controller = new AbortController();
      const signal = controller.signal;

      setTimeout(() => controller.abort(), 2000);

      try {
        await fetch("https://www.google.com", { method: 'HEAD', cache: 'no-store', mode: "no-cors", signal: signal });
        setInternetStatus(true);
      } catch (error) {
        setInternetStatus(false);
      }
    };

    const interval = setInterval(checkInternetConnectivity, 2000);

    return () => clearInterval(interval);
  }, []);

  return (
    <InternetConnectionContext.Provider
      value={{
        isConnected: internetStatus,
      }}
    >
      {children}
    </InternetConnectionContext.Provider>
  );
}

export { InternetConnectionProvider, InternetConnectionContext };
