//

import { openDatabase } from './configDB';

export async function addIndexDBRecord(dbKey, record) {
  try {
    const db = await openDatabase();
    const tx = db.transaction(dbKey, 'readwrite');
    const store = tx.objectStore(dbKey);
    await store.add(record);

    await tx.done;
  } catch (error) {
    console.error(error);
  }
}

export async function getAllIndexDBRecords(dbKey) {
  let allRec = null;

  try {
    const db = await openDatabase();
    const tx = db.transaction(dbKey, 'readonly');
    const store = tx.objectStore(dbKey);
    allRec = await store.getAll();

    await tx.done;
  } catch (error) {
    console.error(error);
  }

  return allRec;
}

export async function updateIndexDBRecord(dbKey, records) {
  try {
    const db = await openDatabase();
    const tx = db.transaction(dbKey, 'readwrite');
    const store = tx.objectStore(dbKey);

    // NOTE: looping threw the each entries is not an efficient way ...
    // will need to find another way in future, for now its fine though ...
    records?.forEach((record) => {
      store.put(record);
    });

    if (window.navigator.onLine) {
      const sync = db.transaction('syncReport', 'readwrite');
      const syncObj = sync.objectStore('syncReport');
      await syncObj.put(new Date().toString(), dbKey);
    }

    await tx.done;
  } catch (error) {
    console.error(error);
  }
}

export async function deleteIndexDBRecord(dbKey, recordId) {
  try {
    const db = await openDatabase();
    const tx = db.transaction(dbKey, 'readwrite');
    const store = tx.objectStore(dbKey);
    await store.delete(recordId);

    await tx.done;
  } catch (error) {
    console.error(error);
  }
}

export async function clearIndexDBStore(dbKey) {
  try {
    const db = await openDatabase();
    const tx = db.transaction(dbKey, 'readwrite');
    const store = tx.objectStore(dbKey);
    await store.clear();

    await tx.done;
  } catch (error) {
    console.error(error);
  }
}

//

export async function getIndexDBSingleRecord(dbKey, recordId) {
  let objectVal = null;

  try {
    const db = await openDatabase();
    const tx = db.transaction(dbKey, 'readonly');
    const store = tx.objectStore(dbKey);
    objectVal = await store.get(Number(recordId));
  } catch (error) {
    console.log(error);
  }

  return objectVal;
}

export async function getIndexDBSyncReport(objectKey) {
  let objectVal = null;

  try {
    const db = await openDatabase();
    const tx = db.transaction('syncReport', 'readonly');
    const store = tx.objectStore('syncReport');
    objectVal = await store.get(objectKey);
  } catch (error) {
    console.log(error);
  }

  return objectVal;
}
