//

import React from 'react';
// @mui
import {
  Button,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
// components
import { LoadingIcons } from '../assets';

// ----------------------------------------------------------------------

export default function CancelConfirmationModal(props) {
  const { isOpen, handleCancel, handleSubmit, isSubmitting } = props;

  return (
    <Dialog open={isOpen} onClose={handleCancel} fullWidth maxWidth={'sm'}>
      <DialogTitle>Confirm</DialogTitle>
      <DialogContent sx={{ pb: 2 }}>
        <Typography variant="body2" sx={{ pt: 2 }}>
          Are you sure ...?
        </Typography>
      </DialogContent>
      <DialogActions sx={{ p: 2 }}>
        <Button onClick={handleCancel} color="inherit">
          Cancel
        </Button>
        <LoadingButton
          disabled={isSubmitting}
          onClick={handleSubmit}
          startIcon={
            isSubmitting ? (
              <LoadingIcons sx={{ width: 28, height: 28 }} />
            ) : null
          }
        >
          Yes
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
