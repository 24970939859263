//

export const setGlobalSettingsToStorage = (settings = []) => {
  for (let index = 0; index < settings.length; index++) {
    const element = settings[index];

    storeKeyValue(element?.key, element?.value);
  }
};

const storeKeyValue = (key, value) => {
  switch (key) {
    case 'UPLOAD_IMAGE_SIZE_MB':
      window.localStorage.setItem('attachment_size', value?.toString() || 10);
      break;

    case 'Storage_System':
      window.localStorage.setItem(
        'storage_system',
        value?.toString() || 'onedrive'
      );
      break;

    default:
      break;
  }
};
