//

import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';
// indexDB
import { updateIndexDBRecord } from '../../indexDB/indexdbUtilsFunctions';
import { INDEX_DB_CONFIG } from '../../indexDB/configDB';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  customerLocationBlocks: [],
  error: null,
};

const slice = createSlice({
  name: 'ranchBlock',
  initialState,
  reducers: {
    // START LOADING
    startLoading(ranchBlock) {
      ranchBlock.isLoading = true;
      ranchBlock.customerLocationBlocks = [];
    },

    // HAS ERROR
    hasError(ranchBlock, action) {
      ranchBlock.isLoading = false;
      ranchBlock.error = action.payload;
      ranchBlock.customerLocationBlocks = [];
    },

    // GET
    getCustomerAddressBlockSuccess(ranchBlock, action) {
      ranchBlock.isLoading = false;
      ranchBlock.customerLocationBlocks = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getPWACustomerRanchBlockAddress() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `/api/v1/customer/crop-locations-blocks`
      );
      if (response?.data?.status === 1) {
        if (Array.isArray(response.data?.data)) {
          await updateIndexDBRecord(
            INDEX_DB_CONFIG.cropLocationBlocks.storeObject,
            response.data.data
          );

          dispatch(
            slice.actions.getCustomerAddressBlockSuccess(response.data.data)
          );
        } else {
          console.log(
            'Invalid API response of v1/customer/crop-locations-blocks'
          );
        }
      } else {
        dispatch(slice.actions.hasError(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
