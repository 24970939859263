//

import { Buffer } from 'buffer';

// store item to localStorage with encryption
export function setItem(key, value) {
  const keyEncode = Buffer.from(key).toString('base64');
  const valueEncode = Buffer.from(value.toString()).toString('base64');

  localStorage.setItem(keyEncode, valueEncode); // encode key/value pair
}

// get item to localStorage with encryption
export function getItem(key) {
  let value = '';
  const keyEncode = Buffer.from(key).toString('base64');
  if (localStorage.getItem(keyEncode)) {
    value = Buffer.from(localStorage.getItem(keyEncode), 'base64').toString();

    setItem(key, value); // add the missing value in store
  }

  return value;
}

// remove encrypted item from storage
export function removeItem(key) {
  const keyEncode = Buffer.from(key).toString('base64');
  localStorage.removeItem(keyEncode);
}
