import { Box } from '@mui/material';

// ----------------------------------------------------------------------

//new report details page -> close icon
export default function CloseFillIcon({ ...other }) {
  return (
    <Box {...other}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={12}
        height={12}
        viewBox="0 0 24 24"
      >
        <g id="evaCloseFill0">
          <g id="evaCloseFill1">
            <path
              id="evaCloseFill2"
              fill="currentColor"
              d="m13.41 12l4.3-4.29a1 1 0 1 0-1.42-1.42L12 10.59l-4.29-4.3a1 1 0 0 0-1.42 1.42l4.3 4.29l-4.3 4.29a1 1 0 0 0 0 1.42a1 1 0 0 0 1.42 0l4.29-4.3l4.29 4.3a1 1 0 0 0 1.42 0a1 1 0 0 0 0-1.42Z"
            />
          </g>
        </g>
      </svg>
    </Box>
  );
}
