//

import { useContext } from 'react';
import { InternetConnectionContext } from '../contexts/InternetConnection';

// ----------------------------------------------------------------------

const useCheckInternet = () => useContext(InternetConnectionContext);

export default useCheckInternet;
