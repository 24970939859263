//

import React from 'react';
// @mui
import { Box } from '@mui/material';
//
import ProgressBar from './ProgressBar';

// ----------------------------------------------------------------------

export default function LoadingScreen() {
  return (
    <>
      <ProgressBar />

      <Box
        style={{
          position: 'fixed',
          width: '100%',
          height: '100%',
          backgroundColor: '#ffffff',
          opacity: 1,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          top: 0,
          left: 0,
          zIndex: 9999,
        }}
      >
        <Box sx={{ maxWidth: '100px' }}>
          <svg
            width="120px"
            height="120px"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 100 100"
            preserveAspectRatio="xMidYMid"
          >
            <path
              ng-attr-d="{{config.pathCmd}}"
              ng-attr-fill="{{config.color}}"
              stroke="none"
              d="M10 50A40 40 0 0 0 90 50A40 42 0 0 1 10 50"
              fill="#1ea63b"
              transform="rotate(324 50 51)"
            >
              <animateTransform
                attributeName="transform"
                type="rotate"
                calcMode="linear"
                values="0 50 51;360 50 51"
                keyTimes="0;1"
                dur="1s"
                begin="0s"
                repeatCount="indefinite"
              />
            </path>
          </svg>
        </Box>
      </Box>
    </>
  );
}
