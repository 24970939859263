//

// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';
// components
import ScrollToTop from './components/ScrollToTop';
import { ProgressBarStyle } from './components/ProgressBar';
import NotistackProvider from './components/NotistackProvider';
import ThemeColorPresets from './components/ThemeColorPresets';
import ThemeLocalization from './components/ThemeLocalization';
import { InternetConnectionProvider } from './contexts/InternetConnection';

// ----------------------------------------------------------------------

export default function App() {
  return (
    <InternetConnectionProvider>
      <ThemeProvider>
        <ThemeColorPresets>
          <ThemeLocalization>
            <NotistackProvider>
              <ProgressBarStyle />
              <ScrollToTop />
              <Router />
            </NotistackProvider>
          </ThemeLocalization>
        </ThemeColorPresets>
      </ThemeProvider>
    </InternetConnectionProvider>
  );
}
